/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";

import { Spin } from "antd";
import moment from "moment";
import momentTimezone from "moment-timezone";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

import {
  emailRegx,
  otpRegx,
  userName,
  USAphoneRegx,
  phoneRegx,
  passwordReg,
  httpUrl,
} from "../../helper/RegularExp/RegularExp";
import { ReactToastify } from "../../helper/ReusableComponents";
import {
  checkIsObjectEmpty,
  getGooglePlaceObjectValues,
  clearLocalStorage,
} from "../../helper/service";
import * as action from "../../store/actions/index";
import { onLogout } from "../../store/actions/loginAction";
import { AxiosInstance, logout } from "../../utilities";
import ForgotPasswordComponent from "../ForgotPasswordComponent/ForgotPasswordComponent";
import LoginComponent from "../LoginComponent/LoginComponent";
import LoginWithOtpComponent from "../LoginComponent/LoginWithOtpComponent";
import OtpVerifyComponent from "../LoginComponent/OtpVerifyComponent";
import SignUpComponent from "../SignUpComponent/SignUpComponent";
import NavBarComponent from "./NavBar";

export function useOutsideAlerter(initialScrollPosition) {
  const ref = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(initialScrollPosition);
  useEffect(() => {
    function listenToScroll() {
      const position = window.pageYOffset;
      setScrollPosition(position);
    }

    // Bind the event listener
    window.addEventListener("scroll", listenToScroll);
    return () => {
      // Unbind the event listener on clean up
      window.addEventListener("scroll", listenToScroll);
    };
  }, [ref]);
  return { ref, scrollPosition, setScrollPosition };
}

export default function HeaderComponent(props) {
  const { ref, scrollPosition, setScrollPosition } = useOutsideAlerter(0);
  const auth = useSelector((state) => state.auth);
  const search = useSelector((state) => state.search);
  const loader = useSelector((state) => state.auth.loading);
  const [isAuthenticated, setIsAuthenticate] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [videoPlayingFullScreen, setVideoPlayingFullScreen] = useState(false);
  const [spin, setSpin] = useState(false);
  const [loginModel, setLoginModel] = useState(false);
  const [headerSearch, setHeaderSearch] = useState({
    event_date: "",
    event_place: "",
    event_type: "",
  });
  const [eventPlace, setEventPlace] = useState({
    event_place: {
      lat: "",
      lng: "",
      address: "",
    },
  });
  const [headerSearchError, setHeaderSearchError] = useState({
    event_date: "",
    event_place: "",
    event_type: "",
  });
  const [signupModel, setSignupModel] = useState(false);
  const [otpModel, setOtpModel] = useState(false);
  const [visible, setVisible] = useState(false);
  const [otpForm, setOtpForm] = useState({ otp: "" });
  const [otpVerify, setOtpVerify] = useState(false);
  const [forgotModel, setForgotModel] = useState(false);
  const [loginForm, setLoginForm] = useState({
    email: "",
    password: "",
  });
  const [signUpForm, setSignUpForm] = useState({
    email: "",
    password: "",
    phone: {
      countryCode: "",
      number: "",
    },
    name: "",
    firstName: "",
    lastName: "",
    isEventPlanner: false,
    //Company fields will be removed on request based on isEventPlanner;
    companyName: "",
    companyLink: "",
  });
  const [formError, setformError] = useState({
    user_name: "",
    email: "",
    password: "",
    phone: "",
    name: "",
    otp: "",
    email_phone: "",
    companyName: "",
    companyLink: "",
  });
  const [forgotForm, setForgotForm] = useState({
    email_phone: "",
  });
  const [timeZone, setTimeZone] = useState("");
  const [cmsData, setCmsData] = useState(
    props.cmsData || {
      heading: "",
      subHeading: "",
    }
  );
  const [show, setShow] = useState(false);

  const [otpScreen, setOtpScreen] = useState({ id: "", type: "" });
  const [signinModel, setSigninModel] = useState(false);

  const [checkRadio, setCheckRadio] = useState(false);
  const onChangeRadio = (data) => {
    if (!data) {
      setformError({ ...formError, companyName: "", companyLink: "" });
    }
    setCheckRadio(data);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (auth && auth.token) {
      // props.history.push("/");
      closeModel();
      setLoginModel(false);
      setSigninModel(false);
      setIsAuthenticate(true);
    } else {
      setIsAuthenticate(false);
    }
    if (auth && auth.otp !== null) {
      setLoginModel(false);
      setOtpVerify(true);
      setSigninModel(false);
      const otpScreenClone = { ...otpScreen };
      otpScreen.id = auth.otpId;
      otpScreen.type = 1;
      setOtpScreen(otpScreen);
    }
  }, [auth]);

  useEffect(() => {
    if (props.showLoginModal) openModel();
  }, [props.showLoginModal]);

  useEffect(() => {
    if (search.searchData) {
      const headerSearchClone = { ...headerSearch };
      headerSearchClone.event_type = search.searchData.eventType;
      headerSearchClone.event_date = moment(search.searchData.date);
      headerSearchClone.event_place = search.searchData.event_place
        ? search.searchData.event_place.replace(/\+/g, " ")
        : "";
      setHeaderSearch(headerSearchClone);
      const eventPlaceClone = { ...eventPlace };
      eventPlaceClone.event_place.lat = search.searchData.latitude;
      eventPlaceClone.event_place.lng = search.searchData.longitude;
      eventPlaceClone.event_place.address = search.searchData.event_place
        ? search.searchData.event_place.replace("+", " ")
        : "";
      setEventPlace(eventPlaceClone);
    }
  }, [search]);

  useEffect(() => {
    setTimeZone(momentTimezone.tz.guess());
  });

  const handleShow = () => setShow(true);

  const disabledDate = (current) => {
    //headerSearch.event_date
    return current && current < moment().add(45, "day");
  };

  const onDatePickerSelect = (date, dateString) => {
    const headerSearchClone = { ...headerSearch };
    const headerSearchErrorClone = { ...headerSearchError };
    headerSearchClone.event_date = moment(date._d);
    headerSearchErrorClone.event_date = "";
    setHeaderSearch(headerSearchClone);
    setHeaderSearchError(headerSearchErrorClone);
  };

  const onSelectChangeHandler = (value) => {
    const headerSearchClone = { ...headerSearch };
    const headerSearchErrorClone = { ...headerSearchError };
    headerSearchErrorClone.event_type = "";
    headerSearchClone.event_type = value;
    setHeaderSearch(headerSearchClone);
    setHeaderSearchError(headerSearchErrorClone);
  };
  const onClickSearchBtn = async (e) => {
    e.preventDefault();
    const headerSearchErrorClone = { ...headerSearchError };
    if (headerSearch.event_date === "") {
      headerSearchErrorClone.event_date = "Required";
    } else {
      headerSearchErrorClone.event_date = "";
    }
    if (
      Object.values(eventPlace.event_place).every((x) => x === "" || x === null)
    ) {
      headerSearchErrorClone.event_place = "Required";
    } else {
      headerSearchErrorClone.event_place = "";
    }

    if (
      headerSearch.event_type !== undefined &&
      headerSearch.event_type.trim() === ""
    ) {
      headerSearchErrorClone.event_type = "Required";
    } else if (headerSearch.event_type === undefined) {
      headerSearchErrorClone.event_type = "Required";
    } else {
      headerSearchErrorClone.event_type = "";
    }
    setHeaderSearchError(headerSearchErrorClone);
    const isEmpty = await checkIsObjectEmpty(headerSearchErrorClone);
    if (isEmpty) {
      const headerSearchClone = { ...headerSearch };
      const start_date = moment
        .tz(moment(headerSearch.event_date._d).format("YYYY-MM-DD"), timeZone)
        .utc();
      const start_date_with_tz = moment
        .tz(
          moment(headerSearch.event_date._d).format("YYYY-MM-DD 00:00:00"),
          timeZone
        )
        .utc();
      const end_date = moment
        .tz(
          moment(headerSearch.event_date._d).format("YYYY-MM-DD 23:59:59"),
          timeZone
        )
        .utc();

      let data = {
        date: start_date_with_tz._d,
        eventType: headerSearchClone.event_type,
        latitude: eventPlace.event_place.lat,
        longitude: eventPlace.event_place.lng,
        timeZone: timeZone,
      };
      localStorage.setItem("searchData", JSON.stringify(data));
      const addressSlug = eventPlace.event_place.address
        .split(", ")
        .join("+")
        .split(" ")
        .join("+")
        .split(".")
        .join("")
        .split("-")
        .join("")
        .split("++")
        .join("+");
      const slug = `/search-results/${headerSearchClone.event_type}/${moment(
        start_date._d
      ).format("YYYY")}/${moment(start_date._d).format("MM")}/${moment(
        start_date._d
      ).format("DD")}/${addressSlug}/@${
        eventPlace.event_place.lat + "," + eventPlace.event_place.lng
      }`;
      props.history.push({
        pathname: slug,
        state: {
          searchData: data,
        },
      });
    }
  };

  /**
   * @author Shashi
   * @param { login  e} value
   *   User Login   functionality
   */
  const onLogin = (e) => {
    const loginData = {
      userName: loginForm.email,
      password: loginForm.password,
      deviceToken: localStorage.getItem("deviceToken") || null,
    };
    e.preventDefault();
    const newformError = { ...formError };
    const loginFormNew = { ...loginForm };
    if (loginFormNew.email === "" && loginFormNew.password === "") {
      newformError.email = "* Please enter an email address";
      newformError.password = "* Please enter a password";
    } else if (loginFormNew.email === "" && loginFormNew.password !== "") {
      newformError.email = "* Please enter an email address";
      newformError.password = "";
    } else if (loginFormNew.email !== "" && loginFormNew.password === "") {
      if (!emailRegx.test(loginFormNew.email)) {
        newformError.email = "* Please enter a valid email address";
      } else {
        newformError.email = "";
      }
      newformError.password = "* Please enter a password";
    } else if (
      loginFormNew.email !== "" &&
      !emailRegx.test(loginFormNew.email)
    ) {
      newformError.email = "* Please enter a valid email address";
      newformError.password = "";
    } else {
      newformError.email = "";
      newformError.password = "";
    }
    setformError(newformError);
    if (newformError.email === "" && newformError.password === "") {
      let redirectUrl =
        props.loginSuccessRedirectUrl !== undefined
          ? props.loginSuccessRedirectUrl
          : "/";
      dispatch(action.LoginAction(loginData, "/client/login", redirectUrl));
    }
  };

  const openModel = () => {
    resetModal();
    setLoginModel(true);
    setSignupModel(false);
    setformError();
    setOtpModel(false);
    setForgotModel(false);
  };
  const openSignUpModel = () => {
    closeModel();
    const formErrorClone = {
      user_name: "",
      email: "",
      password: "",
      phone: "",
      name: "",
      otp: "",
      email_phone: "",
    };
    const signUpFormClone = {
      email: "",
      password: "",
      phone: {
        countryCode: "",
        number: "",
      },
      name: "",
      firstName: "",
      lastName: "",
      isEventPlanner: false,
      companyName: "",
      companyLink: "",
    };

    setSignupModel(true);
    setLoginModel(false);
    setSigninModel(false);
    setSignUpForm(signUpFormClone);
    setformError(formErrorClone);
    setOtpVerify(false);
  };

  const resetModal = () => {
    setSpin(false);
    const signUpFormNew = { ...signUpForm };
    const forgotFormNew = { ...forgotForm };
    const loginFormNew = { ...loginForm };
    signUpFormNew.firstName = "";
    signUpFormNew.lastName = "";
    signUpFormNew.email = "";
    signUpFormNew.phone.number = "";
    signUpFormNew.phone.countryCode = "";
    signUpFormNew.password = "";
    forgotFormNew.email_phone = "";

    loginFormNew.email = "";
    loginFormNew.password = "";
    setVisible(false);
    setLoginForm(loginFormNew);
    setForgotForm(forgotFormNew);
    setSignUpForm(signUpFormNew);
    setLoginModel(false);
    setSigninModel(false);
    setSignupModel(false);
    setformError();
    setOtpModel(false);
    setOtpVerify(false);
    setForgotModel(false);
  };

  /**
   * @author Shashi
   * @param { model  } value
   *   User Login model close   functionality
   */
  const closeModel = () => {
    resetModal();
    // dispatch(action.updateOtp({ id: null, otp: null }));
    if (typeof props.onModalClose === "function") {
      props.onModalClose(false);
    }
  };

  /**
   * @author Shashi
   * @param { model  } value
   *   User logout    functionality
   */
  const logoutFunction = () => {
    dispatch(onLogout());
    logout();
    toast.success(
      <ReactToastify title="Success" desc="Logged out successfully" />
    );
    // window.location.href = window.location.pathname;
    // window.location.href = "/";
  };
  /**
   * @author Shashi
   * @param { model  } value
   *   User show password    functionality
   */
  const on_password_visible = () => {
    setVisible(!visible);
  };
  /**
   * @author Shashi
   * @param { model  e} value
   *   User input change   functionality
   */
  const onInputChange = (e) => {
    const { name, value } = e.target;
    const newformError = { ...formError };
    switch (name) {
      case "email":
        value.trim() === ""
          ? (newformError.email = "Please enter an email address")
          : !emailRegx.test(value.trim())
          ? (newformError.email = "Please enter a valid email address")
          : (newformError.email = "");
        break;
      case "password":
        value === ""
          ? (newformError.password = "Please enter a password")
          : (newformError.password = "");
        break;
      default:
        break;
    }
    setformError(newformError);
    setLoginForm({ ...loginForm, [name]: value });
  };
  /**
   * @author Shashi
   * @param { input  e} value
   *   User input change sign up   functionality
   */
  const onInputSignUpChange = (e) => {
    const { name, value, checked } = e.target;
    const newformError = { ...formError };
    switch (name) {
      case "email":
        value.trim() === ""
          ? (newformError.email = "")
          : !emailRegx.test(value.trim())
          ? (newformError.email = "Please enter a valid email address")
          : (newformError.email = "");
        break;
      case "password":
        value === ""
          ? (newformError.password = "")
          : !passwordReg.test(value.trim())
          ? (newformError.password =
              "Password should be at least 8 characters long, contain at least 1 number, 1 capital letter and 1 special character (! @ # $ % * & -)")
          : (newformError.password = "");
        break;
      case "firstName":
        value.trim() === ""
          ? (newformError.firstName = "")
          : !userName.test(value.trim())
          ? (newformError.firstName = "Please enter a valid first name")
          : (newformError.firstName = "");
        break;
      case "lastName":
        value.trim() === ""
          ? (newformError.lastName = "")
          : !userName.test(value.trim())
          ? (newformError.lastName = "Please enter a valid last name")
          : (newformError.lastName = "");
        break;
      case "companyName":
        if (signUpForm.isEventPlanner) {
          value.trim() === ""
            ? (newformError.companyName = "")
            : !userName.test(value.trim())
            ? (newformError.companyName = "Please enter a valid company name")
            : (newformError.companyName = "");
        }
        break;
      case "companyLink":
        if (signUpForm.isEventPlanner) {
          value.trim() === ""
            ? (newformError.companyLink = "")
            : !httpUrl.test(value.trim())
            ? (newformError.companyLink = "Please enter a valid Company link")
            : (newformError.companyLink = "");
        }
        break;
      // case "phone":
      //   value.trim() === ""
      //     ? (newformError.phone =
      //       "Please enter  phone no")
      //     : !USAphoneRegx.test(value.trim())
      //       ? (newformError.phone = "Please enter valid phone no")
      //       : (newformError.phone = "");
      //   break;
      default:
        break;
    }

    if (name === "isEventPlanner") {
      setSignUpForm({ ...signUpForm, [name]: checked });
      if (!checked) {
        newformError.companyName = "";
        newformError.companyLink = "";
      }
    } else {
      setSignUpForm({ ...signUpForm, [name]: value });
    }
    setformError(newformError);
  };

  const onPhoneInputChanges = (value, country, e, formattedValue) => {
    if (e.target) {
      const { name } = e.target;
      const formErrorNew = { ...formError };
      switch (name) {
        case "phone":
          formattedValue.trim() === ""
            ? (formErrorNew.phone = "")
            : !phoneRegx.test(formattedValue.trim()) &&
              formattedValue.trim() !== ""
            ? (formErrorNew.phone = "Please enter a valid phone number")
            : (formErrorNew.phone = "");
          break;
        default:
          break;
      }

      let user_detail = {};
      user_detail["number"] = formattedValue;
      user_detail["countryCode"] = "+" + country.dialCode;
      setformError(formErrorNew);
      setSignUpForm({ ...signUpForm, [name]: user_detail });
    }
  };

  /**
   * @author Shashi
   * @param { submit  e} value
   *   User submit sign up and api call   functionality
   */
  const onSignUp = (e) => {
    e.preventDefault();
    const newformError = { ...formError };

    const signUpFormNew = { ...signUpForm };
    if (signUpFormNew.firstName.trim() === "") {
      newformError.firstName = "Please enter a first name";
    } else if (
      !userName.test(signUpFormNew.firstName.trim()) &&
      signUpFormNew.firstName.trim() !== ""
    ) {
      newformError.firstName = "Please enter a valid first name";
    } else {
      newformError.firstName = "";
    }

    if (signUpFormNew.lastName.trim() === "") {
      newformError.lastName = "Please enter a last name";
    } else if (
      !userName.test(signUpFormNew.lastName.trim()) &&
      signUpFormNew.lastName.trim() !== ""
    ) {
      newformError.lastName = "Please enter a valid last name";
    } else {
      newformError.lastName = "";
    }

    if (signUpFormNew.email.trim() === "") {
      newformError.email = "Please enter an email address";
    } else if (
      !emailRegx.test(signUpFormNew.email.trim()) &&
      signUpFormNew.email.trim() !== ""
    ) {
      newformError.email = "Please enter a valid email address";
    } else {
      newformError.email = "";
    }
    if (
      Object.values(signUpFormNew.phone).every((x) => x === "" || x === null)
    ) {
      newformError.phone = "Please enter a phone number";
    } else if (
      !phoneRegx.test(signUpFormNew.phone.number.trim()) &&
      signUpFormNew.phone.number.trim() !== ""
    ) {
      newformError.phone = "Please enter a valid phone number";
    } else {
      newformError.phone = "";
    }
    if (signUpFormNew.password.trim() === "") {
      newformError.password = "Please enter a password";
    } else if (
      !passwordReg.test(signUpFormNew.password.trim()) &&
      signUpFormNew.password.trim() !== ""
    ) {
      newformError.password =
        "Password should be at least 8 characters long, contain at least 1 number, 1 capital letter and 1 special character (! @ # $ % * & -)";
    } else {
      newformError.password = "";
    }
    if (signUpForm.isEventPlanner) {
      if (signUpFormNew?.companyName.trim() === "") {
        newformError.companyName = "Please enter a company name";
      } else if (
        !userName.test(signUpFormNew.companyName.trim()) &&
        signUpFormNew.companyName.trim() !== ""
      ) {
        newformError.companyName = "Please enter a company name";
      } else {
        newformError.companyName = "";
      }

      if (signUpFormNew?.companyLink.trim() === "") {
        newformError.companyLink = "Please enter a valid URL";
      } else if (
        !httpUrl.test(signUpFormNew.companyLink.trim()) &&
        signUpFormNew.companyLink.trim() !== ""
      ) {
        newformError.companyLink = "Please enter a valid URL";
      } else {
        newformError.companyLink = "";
      }
    } else {
      newformError.companyName = "";
      newformError.companyLink = "";
    }

    setformError(newformError);

    const isEmpty = Object.values(newformError).every(
      (x) => x === null || x === ""
    );
    if (isEmpty) {
      setSpin(true);
      let data = {
        firstName: signUpForm.firstName,
        lastName: signUpForm.lastName,
        email: signUpForm.email,
        password: signUpForm.password,
        number: signUpForm.phone.number,
        countryCode: signUpForm.phone.countryCode,
        isEventPlanner: signUpForm.isEventPlanner,
      };

      if (signUpForm.isEventPlanner) {
        data.companyName = signUpForm.companyName;
        data.companyLink = signUpForm.companyLink;
      }
      AxiosInstance(4007, "application/json")
        .post("/register/user", data)
        .then((resp) => {
          const response = resp && resp.data;
          if (
            response &&
            (response.status === 200 || response.status === "200")
          ) {
            const otpScreenClone = { ...otpScreen };
            otpScreenClone.id = response.data.id;
            otpScreenClone.type = 0;
            setOtpScreen(otpScreenClone);
            toast.success(
              <ReactToastify title="Success" desc={response.msg} />
            );
            setSignupModel(false);
            setOtpForm({ otp: "" });
            setOtpVerify(true);
            setSpin(false);
          } else if (
            response &&
            (response.status === 400 || response.status === "400")
          ) {
            toast.error(<ReactToastify title="Error" desc={response.msg} />);
            setSpin(false);
          }
        })
        .catch((err) => {
          var error = err && err.response;
          if (error && (error.status === "403" || error.status === 403)) {
            logout();
          } else if (
            error &&
            (error.status === "500" || error.status === 500)
          ) {
            console.log("Error", error.msg);
            setSpin(false);
          }
        });
    }
  };
  /**
   * @author Shashi
   *
   *   User otp model show functionality
   */
  const onClickOtpModel = () => {
    closeModel();
    setOtpModel(true);
    setLoginModel(false);
    setSignupModel(false);
    setformError();
    setSigninModel(false);
  };
  /**
   * @author Shashi
   * @param { input  e} value
   *   User input otp   functionality
   */
  const onInputOtpChange = (e) => {
    const { name, value } = e.target;
    const newformError = { ...formError };
    switch (name) {
      case "phone":
        value.trim() === ""
          ? (newformError.phone = "Please enter a phone number")
          : !USAphoneRegx.test(value.trim())
          ? (newformError.phone = "Please enter a valid phone number")
          : (newformError.phone = "");
        break;
      default:
        break;
    }
    setformError(newformError);
    setSignUpForm({ ...signUpForm, [name]: value });
  };
  /**
   * @author Shashi
   * @param { submit  e} value
   *   User get otp api call   functionality
   */
  const onGetOtp = (e) => {
    e.preventDefault();
    const newformError = { ...formError };
    const signUpFormNew = { ...signUpForm };

    if (
      Object.values(signUpFormNew.phone).every((x) => x === "" || x === null)
    ) {
      newformError.phone = "Please enter a phone number";
    } else if (
      !phoneRegx.test(signUpFormNew.phone.number.trim()) &&
      signUpFormNew.phone.number.trim() !== ""
    ) {
      newformError.phone = "Please enter a valid phone number";
    } else {
      newformError.phone = "";
    }
    setformError(newformError);
    let data = {
      number: signUpForm.phone.number,
    };
    const isEmpty = Object.values(newformError).every(
      (x) => x === null || x === ""
    );
    if (isEmpty) {
      setSpin(true);
      AxiosInstance(4007, "application/json")
        .post("/client/login/otp", data)
        .then((resp) => {
          const response = resp && resp.data;
          if (
            response &&
            (response.status === 200 || response.status === "200")
          ) {
            toast.success(
              <ReactToastify title="Success" desc={response.msg} />
            );
            const otpScreenClone = { ...otpScreen };
            otpScreenClone.type = 1;
            otpScreenClone.id = response.id;
            setOtpScreen(otpScreenClone);
            setOtpForm({ otp: "" });
            setOtpVerify(true);
            setOtpModel(false);
            setSpin(false);
          } else if (
            response &&
            (response.status === 400 || response.status === "400")
          ) {
            toast.error(<ReactToastify title="Error" desc={response.msg} />);
            setSpin(false);
          }
        })
        .catch((err) => {
          var error = err && err.response;
          if (error && (error.status === "403" || error.status === 403)) {
            logout();
          } else if (
            error &&
            (error.status === "500" || error.status === 500)
          ) {
            console.log("Error", error.msg);
            setSpin(false);
          }
        });
    }
  };
  const get_otp = (data) => {
    setSpin(true);
    AxiosInstance(4007, "application/json")
      .post("/client/login/otp", data)
      .then((resp) => {
        const response = resp && resp.data;
        if (
          response &&
          (response.status === 200 || response.status === "200")
        ) {
          toast.success(<ReactToastify title="Success" desc={response.msg} />);
          const otpScreenClone = { ...otpScreen };
          otpScreenClone.type = 1;
          otpScreenClone.id = response.id;
          setOtpScreen(otpScreenClone);
          setSpin(false);
        } else if (
          response &&
          (response.status === 400 || response.status === "400")
        ) {
          toast.error(<ReactToastify title="Error" desc={response.msg} />);
          setSpin(false);
        }
      })
      .catch((err) => {
        var error = err && err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          logout();
        } else if (error && (error.status === "500" || error.status === 500)) {
          console.log("Error", error.msg);
          setSpin(false);
        }
      });
  };
  /**
   * @author Shashi
   * @param { input  otp} value
   *   User otp input change   functionality
   */
  const otpCodeChange = (otp) => {
    const otpFormErrorNew = { ...formError };
    switch ("otp") {
      case "otp":
        otp.trim() === ""
          ? (otpFormErrorNew.otp = "")
          : !otpRegx.test(otp.trim())
          ? (otpFormErrorNew.otp = "Please enter a valid OTP")
          : (otpFormErrorNew.otp = "");
        break;
      default:
        break;
    }
    setformError(otpFormErrorNew);
    setOtpForm({ ...otpForm, otp: otp });
  };
  /**
   * @author Shashi
   * @param { submit  e} value
   *   User otp api call  functionality
   */
  const onSubmitOtp = (e) => {
    e.preventDefault();
    let data = {
      id: otpScreen.id,
      otp: otpForm.otp,
      type: otpScreen.type,
      rememberDevice: true,
    };
    const otpFormErrorNew = { ...formError };
    if (otpForm.otp.trim() === "") {
      otpFormErrorNew.otp = "Please enter a OTP";
    } else if (!otpRegx.test(otpForm.otp.trim()) && otpForm.otp.trim() !== "") {
      otpFormErrorNew.otp = "Please enter a valid OTP";
    } else {
      otpFormErrorNew.otp = "";
    }
    setformError(otpFormErrorNew);
    if (otpFormErrorNew.otp === "") {
      let redirectUrl =
        props.loginSuccessRedirectUrl !== undefined
          ? props.loginSuccessRedirectUrl
          : "/";
      dispatch(action.LoginAction(data, "/client/verifyOtp", redirectUrl));
    }
  };
  /**
   * @author Shashi
   * @param { model  e} value
   *   User open model forgot password   functionality
   */
  const onClickforgotModel = (e) => {
    closeModel();
    setForgotModel(true);
    setLoginModel(false);
    setSigninModel(false);
  };
  /**
   * @author Shashi
   * @param {input  e} value
   *   User input change forgot password   functionality
   */
  const onInputforgotChange = (e) => {
    const { name, value } = e.target;
    const newformError = { ...formError };
    switch (name) {
      case "email_phone":
        value.trim() === ""
          ? (newformError.email_phone = "")
          : !emailRegx.test(value.trim())
          ? (newformError.email_phone = "Please enter an email address")
          : (newformError.email_phone = "");
        break;
      default:
        break;
    }
    setformError(newformError);
    setForgotForm({ ...forgotForm, [name]: value });
  };
  /**
   * @author Shashi
   * @param { submit  e} value
   *   User forgot password api call   functionality
   */
  const onSubmitForgot = (e) => {
    e.preventDefault();
    let data = {
      email: forgotForm.email_phone,
    };
    const forFormErrorNew = { ...formError };
    if (forgotForm.email_phone.trim() === "") {
      forFormErrorNew.email_phone = "Please enter an email address";
    } else if (
      !emailRegx.test(forgotForm.email_phone.trim()) &&
      forgotForm.email_phone.trim() !== ""
    ) {
      forFormErrorNew.email_phone = "Please enter a valid email address";
    } else {
      forFormErrorNew.email_phone = "";
    }
    setformError(forFormErrorNew);
    if (forFormErrorNew.email_phone === "") {
      setSpin(true);
      AxiosInstance(4007, "application/json")
        .post("/client/forgotPassword", data)
        .then((resp) => {
          const response = resp && resp.data;
          if (
            response &&
            (response.status === 200 || response.status === "200")
          ) {
            toast.success(
              <ReactToastify title="Success" desc={response.msg} />
            );
            closeModel();
            setSpin(false);
          } else if (
            response &&
            (response.status === 400 || response.status === "400")
          ) {
            toast.error(<ReactToastify title="Error" desc={response.msg} />);
            setSpin(false);
          }
        })
        .catch((err) => {
          var error = err && err.response;
          if (error && (error.status === "403" || error.status === 403)) {
            logout();
          } else if (
            error &&
            (error.status === "500" || error.status === 500)
          ) {
            console.log("Error", error.msg);
            setSpin(false);
          }
        });
    }
  };

  const resend_otp = () => {
    // let data = {
    //   number: signUpForm.phone.number,
    // };
    // get_otp(data);
    resend_otp_api();
  };

  const resend_otp_api = () => {
    let data = {
      id: otpScreen.id,
      type: otpScreen.type,
      number: signUpForm.phone.number,
    };
    setSpin(true);
    AxiosInstance(4007, "application/json")
      .post("/resendOtp", data)
      .then((resp) => {
        const response = resp && resp.data;
        if (
          response &&
          (response.status === 200 || response.status === "200")
        ) {
          toast.success(<ReactToastify title="Success" desc={response.msg} />);
          otpScreen.id = response.id;
          setOtpScreen(otpScreen);
          setSpin(false);
        } else if (
          response &&
          (response.status === 400 || response.status === "400")
        ) {
          toast.error(<ReactToastify title="Error" desc={response.msg} />);
          setSpin(false);
        }
      })
      .catch((err) => {
        var error = err && err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          logout();
        } else if (error && (error.status === "500" || error.status === 500)) {
          console.log("Error", error.msg);
          setSpin(false);
        }
      });
  };

  /**
   * Shashi code end
   */

  const onGooglePlaceSelect = (place) => {
    let formatedData = getGooglePlaceObjectValues(place);
    const eventPlaceClone = { ...eventPlace };
    const addressSlug = formatedData.address
      .split(", ")
      .join("+")
      .split(" ")
      .join("+")
      .split(".")
      .join("")
      .split("-")
      .join("")
      .split("++")
      .join("+");
    eventPlaceClone.event_place.lat = formatedData.lat;
    eventPlaceClone.event_place.lng = formatedData.lng;
    eventPlaceClone.event_place.address = formatedData.address;
    const headerSearchErrorClone = { ...headerSearchError };
    headerSearchErrorClone.event_place = "";
    setEventPlace(eventPlaceClone);
    setHeaderSearchError(headerSearchErrorClone);
  };

  const onGooglePlaceChange = (event) => {
    if (event.target.value === "") {
      const eventPlaceClone = { ...eventPlace };
      eventPlaceClone.event_place.lat = "";
      eventPlaceClone.event_place.lng = "";
      eventPlaceClone.event_place.address = "";
      setEventPlace(eventPlaceClone);
      const headerSearchErrorClone = { ...headerSearchError };
      headerSearchErrorClone.event_place = "Required";
      setHeaderSearchError(headerSearchErrorClone);
    }
    // let formatedData = getGooglePlaceObjectValues(place);
    // const eventPlaceClone = { ...eventPlace };
    // const addressSlug= formatedData.address.split(", ").join('+').split(" ").join("+").split(".").join("").split("-").join("").split("++").join("+");
    // eventPlaceClone.event_place.lat = formatedData.lat;
    // eventPlaceClone.event_place.lng = formatedData.lng;
    // eventPlaceClone.event_place.address=formatedData.address;
    // const headerSearchErrorClone = { ...headerSearchError };
    // headerSearchErrorClone.event_place = "";
    // setEventPlace(eventPlaceClone);
    // setHeaderSearchError(headerSearchErrorClone);
  };

  const playVideoBackground = () => {
    setShowVideo(!showVideo);
  };

  const handlePlayClicked = () => {
    vidRef.current.muted = false;
    setVideoPlayingFullScreen(true);
    document.body.classList.add("video-playing");
    headerOverlayRef.current.classList.add("header_overlay-play");
  };

  const handlePauseClicked = () => {
    vidRef.current.muted = true;
    setVideoPlayingFullScreen(false);
    document.body.classList.remove("video-playing");
    headerOverlayRef.current.classList.remove("header_overlay-play");
  };

  return (
    <>
      <Spin spinning={spin} />
      <LoginComponent
        visible={loginModel}
        onCancelModal={closeModel}
        onInputChange={onInputChange}
        formError={formError}
        onLogin={onLogin}
        loader={loader}
        loginForm={loginForm}
        pwd_visible={visible}
        on_password_visible={on_password_visible}
        openSignUpModel={openSignUpModel}
        onClickOtpModel={onClickOtpModel}
        onClickforgotModel={onClickforgotModel}
      />
      <SignUpComponent
        visible={signupModel}
        onCancelModal={closeModel}
        onInputChange={onInputSignUpChange}
        formError={formError}
        loginForm={loginForm}
        onSignUp={onSignUp}
        openModel={openModel}
        onPhoneInputChanges={onPhoneInputChanges}
        on_password_visible={on_password_visible}
        pwd_visible={visible}
        signUpForm={signUpForm}
        onChangeRadio={onChangeRadio}
        checkRadio={checkRadio}
      />
      <LoginWithOtpComponent
        visible={otpModel}
        onCancelModal={closeModel}
        onGetOtp={onGetOtp}
        onInputChange={onInputOtpChange}
        formError={formError}
        openModel={openModel}
        signUpForm={signUpForm}
        onPhoneInputChanges={onPhoneInputChanges}
      />
      <OtpVerifyComponent
        visible={otpVerify}
        onCancelModal={closeModel}
        openSignUpModel={openSignUpModel}
        otpCodeChange={otpCodeChange}
        otpForm={otpForm}
        onSubmitOtp={onSubmitOtp}
        formError={formError}
        resend_otp={resend_otp}
      />
      <ForgotPasswordComponent
        visible={forgotModel}
        onCancelModal={closeModel}
        onInputforgotChange={onInputforgotChange}
        formError={formError}
        openModel={openModel}
        onSubmitForgot={onSubmitForgot}
        forgotForm={forgotForm}
      />
      {!props.hideNav  && <NavBarComponent
        {...props}
        logout={logoutFunction}
        isAuthenticated={isAuthenticated}
        openModel={openModel}
        handleShow={handleShow}
        // openModelSignin={openModelSignin}
      />}
    </>
  );
}
